import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PublisherModel, ResultModel } from '@app/_models';
 
import { Observable } from 'rxjs';

const baseUrl = `/api/v1/publishers`;

@Injectable({
  providedIn: 'root'
})
export class PublishersService {
  constructor(private http: HttpClient) {}

  findall(): Observable<PublisherModel[]> {
    return this.http.get<PublisherModel[]>(`${baseUrl}/all/`);
  }
  addnew(publisher:PublisherModel):Observable<ResultModel>{
    return this.http.post<ResultModel>(`${baseUrl}`, publisher);
  }
}
