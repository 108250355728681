import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BookModel,
  BookauthorModel,
  BooksApi,
  Bookstock,
  Pagination,
  PublisherModel,
  ResultModel
} from '@app/_models';

import { Observable, of, tap } from 'rxjs';

const baseUrl = `/api/v1/books`;
const baseUrl2 = `/api/v1/bookstock`;
@Injectable({
  providedIn: 'root'
})
export class BooksService {
  opts: PublisherModel[] = [];

  constructor(private http: HttpClient) {}

  findall(): Observable<BookModel[]> {
    return this.http.get<BookModel[]>(`${baseUrl}/all/`);
  }
  getBooksApi(pagination:Pagination): Observable<BooksApi> {
    return this.http.post<BooksApi>(`${baseUrl}/paged`,pagination);
  }

  findById(id: number): Observable<BookauthorModel> {
    return this.http.get<BookauthorModel>(`${baseUrl}/${id}`);
  }

  addnew(author: BookModel): Observable<ResultModel> {
    return this.http.post<ResultModel>(`${baseUrl}/update`, author);
  }
  addnewwithImage(book: BookModel, file: File): Observable<ResultModel> {
    const formData: FormData = new FormData();

    formData.append('file1', file);
    formData.append(
      'book',
      new Blob([JSON.stringify(book)], {
        type: 'application/json'
      })
    );
    return this.http.post<ResultModel>(`${baseUrl}`, formData);
  }

  addnewAuthors(author: BookModel): Observable<ResultModel> {
    return this.http.post<ResultModel>(`${baseUrl}/updateAuthors`, author);
  }

  addnewImage(book: BookModel, file: File): Observable<ResultModel> {
    const formData: FormData = new FormData();
    console.log(file);
    formData.append('file1', file);
    formData.append(
      'book',
      new Blob([JSON.stringify(book)], {
        type: 'application/json'
      })
    );
    return this.http.post<ResultModel>(`${baseUrl}/updateCoverpage`, formData);
  }
  getData(publisherName: string) {
    return this.opts.length
      ? of(this.opts)
      : this.http
          .post<PublisherModel[]>(`${baseUrl}/lookupPublisher`, publisherName)
          .pipe(tap((data) => (this.opts = data)));
  }
  updateStock(stock:Bookstock):Observable<ResultModel>{
    return this.http.post<ResultModel>(`${baseUrl2}`, stock);
  }

  
  findallstocks(id:number): Observable<Bookstock[]> {
    return this.http.get<Bookstock[]>(`${baseUrl2}/bookstocklist/${id}`);
  }
}
